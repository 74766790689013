<header>
  <h1>Palette</h1>

  <div>This page contains the graphic palette used throughout the Logic Suite application</div>
</header>
<lib-theme-switch [showLabel]="true"></lib-theme-switch>

<section>
  <div>
    <header><span>Primary</span><mat-checkbox></mat-checkbox></header>
    @for (color of primary; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Accent</header>
    @for (color of accent; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Warning</header>
    @for (color of warning; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Success</header>
    @for (color of green; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Grey</header>
    @for (color of grey; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Graph - default</header>
    @for (color of graph; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Graph - vibrant</header>
    @for (color of vibrant; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Graph - new palette</header>
    @for (color of newColors; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
</section>
