<lib-collapse-panel [visible]="hasHierarchy()"
                    [handleType]="hasHierarchy() ? 'icon' : 'none'"
                    [initialWidth]="320"
                    [minWidth]="320">
  <div class="flex flex-col gap-1 w-full h-full px-4 pt-5 overflow-y-auto bg-surface-0 dark:bg-surface-800">

    <div class="flex gap-1">
      <div
        class="hierarchy-tab-button"
        [ngClass]="{'active': selectedTab() === 'full'}"
        (click)="selectedTab.set('full')">
        <svg-icon class="min-w-5 min-h-5" size="sm" key="list"></svg-icon>
        <p class="text-sm">Hierarchy</p>
      </div>

      <div
        class="hierarchy-tab-button"
        [ngClass]="{'active': selectedTab() === 'favorites'}"
        (click)="selectedTab.set('favorites')">
        <svg-icon class="min-w-5 min-h-5" size="sm" key="star"></svg-icon>
        <p class="text-sm">Favorites</p>
      </div>
    </div>

    <p-divider></p-divider>

    @switch (selectedTab()) {
      @case ('full') {
        <lib-full-hierarchy
          [hierarchy]="hierarchyService.hierarchy()"
          [favorites]="favoriteService.favorites()"
          [selectedNode]="hierarchyService.selectedNode()"
          [showConfigMenu]="showConfigMenu()"
          (selectNode)="selectNode($event)"
          (toggleFavorite)="toggleFavorite($event)"
        ></lib-full-hierarchy>
      }
      @case ('favorites') {
        @if (favoriteService.favorites().size === 0) {
          <div class="flex flex-col items-center justify-center text-surface-400 h-full">
            <svg-icon class="text-surface-400" size="xxl" key="star"></svg-icon>
            <p class="text-center text-lg">No favorites.</p>
            <p (click)="selectedTab.set('full')" class="text-center text-sm cursor-pointer hover:underline">Add
              favorites from the hierarchy.</p>
          </div>
        } @else {
          <lib-favorite-hierarchy
            [hierarchy]="hierarchyService.hierarchy()"
            [favorites]="favoriteService.favorites()"
            [selectedNode]="hierarchyService.selectedNode()"
            [showConfigMenu]="showConfigMenu()"
            (selectNode)="selectNode($event)"
            (toggleFavorite)="toggleFavorite($event)"
          ></lib-favorite-hierarchy>
        }
      }
    }
  </div>
</lib-collapse-panel>
