import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideSvgIcons, provideSvgIconsConfig, SvgIconComponent } from '@ngneat/svg-icon';
import { asBuilding01Icon } from './icons/building-01';
import { asBuilding02Icon } from './icons/building-02';
import { asBuilding03Icon } from './icons/building-03';
import { asChevronDownIcon } from './icons/chevron-down';
import { asChevronLeftIcon } from './icons/chevron-left';
import { asChevronRightIcon } from './icons/chevron-right';
import { asChevronUpIcon } from './icons/chevron-up';
import { asHelpSquareIcon } from './icons/help-square';
import { asMeterGroupIcon } from './icons/meter-group';
import { asMeterIcon } from './icons/meter';
import { asStarFilledIcon } from './icons/star-filled';
import { asStarIcon } from './icons/star';
import { asSubMeterIcon } from './icons/sub-meter';
import { asSubsubMeterIcon } from './icons/subsub-meter';
import { asSubsubsubMeterIcon } from './icons/subsubsub-meter';
import { asVirtualMeterIcon } from './icons/virtual-meter';
import { asBellIcon } from './icons/bell';
import { asChipIcon } from './icons/chip';
import { asCogIcon } from './icons/cog';
import { asEnergyIcon } from './icons/energy';
import { asFlowIcon } from './icons/flow';
import { asMoneyIcon } from './icons/money';
import { asUsersIcon } from './icons/users';
import { asSwitchArrowsIcon } from './icons/switch-arrows';
import { asNoovaLogoIcon } from './icons/noova-logo';
import { asZoneIcon } from './icons/zone';
import { asResourceIcon } from './icons/resource';
import { asRoomIcon } from './icons/room';
import { asFloorIcon } from './icons/floor';
import { asSectionIcon } from './icons/section';
import { asSearchIcon } from './icons/search';
import { asListIcon } from './icons/list';
import { asHandleHorizontalIcon } from './icons/handle-horizontal';
import { asHandleChevronRightIcon } from './icons/handle-chevron-right';
import { asHandleChevronLeftIcon } from './icons/handle-chevron-left';
import { asPlusIcon } from './icons/plus';
import { asAclIcon } from './icons/acl';
import { asBaseLoadIcon } from './icons/base-load';
import { asClipboardIcon } from './icons/clipboard';
import { asDropletsIcon } from './icons/droplets';
import { asEtDeviationIcon } from './icons/et-deviation';
import { asPeakLoadIcon } from './icons/peak-load';
import { asThermometerIcon } from './icons/thermometer';
import { asEditBoxIcon } from './icons/edit-box';
import { asCustomerIcon } from './icons/customer';
import { asCrossIcon } from './icons/cross';
import { asTrashIcon } from './icons/trash';
import { asDotIcon } from './icons/dot';

/**
 * Manages all icons in the suite.
 *
 * To add an icon, first add the desired svg to ./assets.
 * Then run `bun run svg` to generate the ts icon.
 * Then add your icon to `provideSvgIcons` below.
 * You can then use icons like:
 *
 * ```html
 * <svg-icon key="star"></svg-icon>
 * ```
 */
@NgModule({
  imports: [CommonModule, SvgIconComponent],
  providers: [
    provideSvgIconsConfig({
      sizes: {
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '28px',
        xl: '32px',
        xxl: '36px',
      },
      defaultSize: 'md',
    }),
    provideSvgIcons([
      asAclIcon,
      asBaseLoadIcon,
      asBellIcon,
      asBuilding01Icon,
      asBuilding02Icon,
      asBuilding03Icon,
      asChevronDownIcon,
      asChevronLeftIcon,
      asChevronRightIcon,
      asChevronUpIcon,
      asChipIcon,
      asClipboardIcon,
      asCogIcon,
      asCrossIcon,
      asCustomerIcon,
      asDotIcon,
      asDropletsIcon,
      asEditBoxIcon,
      asEnergyIcon,
      asEtDeviationIcon,
      asFloorIcon,
      asFlowIcon,
      asHandleChevronLeftIcon,
      asHandleChevronRightIcon,
      asHandleHorizontalIcon,
      asHelpSquareIcon,
      asListIcon,
      asMeterGroupIcon,
      asMeterIcon,
      asMoneyIcon,
      asNoovaLogoIcon,
      asPeakLoadIcon,
      asPlusIcon,
      asResourceIcon,
      asRoomIcon,
      asSearchIcon,
      asSectionIcon,
      asStarFilledIcon,
      asStarIcon,
      asSubMeterIcon,
      asSubsubMeterIcon,
      asSubsubsubMeterIcon,
      asSwitchArrowsIcon,
      asThermometerIcon,
      asTrashIcon,
      asUsersIcon,
      asVirtualMeterIcon,
      asZoneIcon,
    ]),
  ],
  exports: [SvgIconComponent],
})
export class IconModule {}
